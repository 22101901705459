.admin-dialog {
	.MuiDialog-paper {
		height: 90vh;
		max-height: 90vh;

		.MuiDialogTitle-root {
			padding-bottom: 0;
		}

		.tab-pannel {
			height: 100%;

			.user-list-container {
				margin-top: 16px;

				.user-id {
					width: 80px;
				}
				.phone {
					width: 130px;
				}
				.eco-poitns {
					width: 70px;
				}
				.platform {
					width: 80px;
				}
				.e-mail {
					width: 170px;
				}
				.role {
					width: 200px;
				}
				.region {
					width: 300px;
				}
			}

			.region-container {
				height: 100%;
				.left-col {
					display: flex;
					flex-direction: column;
				}

				.region-list-container {
					flex-grow: 1;
					position: relative;

					.region-list {
						background-color: $white;
						border-radius: 8px;
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						overflow-y: auto;
					}
				}

				.right-col {
					display: flex;
					flex-direction: column;
					.edit-container {
						flex-grow: 1;

						.about {
							flex-grow: 1;
							.ck-content {
								height: 50vh;
								max-height: 50vh;
								overflow: auto;
							}
						}

						.save-button {
							float: right;
						}
					}
				}
			}
		}
		.progress-container {
			height: 5px;
			width: 100%;
			margin-bottom: -5px;
		}
	}
}
