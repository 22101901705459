.news-header {
	margin-bottom: 16px;
	.btn-add {
		margin-top: -8px;
	}
}
.news {
	.list-container {
		max-height: 100%;
		overflow: auto;

		.news-item {
			width: 100%;
			padding: 20px;
			border: 1px solid $gray-light;
			border-radius: 8px;
			margin-top: 20px;
			cursor: pointer;

			&:first-child {
				margin-top: 0;
			}

			&:hover {
				background-color: $gray-lightest;
				border-color: $gray-lightest;
			}

			&.placeholder {
				cursor: default;

				&:hover {
					background-color: transparent;
					border-color: $gray-light;
				}

				.container {
					.promo {
						span {
							width: 80px;
							height: 50px;
							border-radius: 4px;
						}
					}
					.content {
						.MuiTypography-body2 {
							width: 100%;
							span {
								height: 16px;
								margin-bottom: 4px;
								border-radius: 10px;
							}
							&:last-child {
								width: 50%;
							}
						}
						.MuiTypography-subtitle1 {
							span {
								height: 14px;
								width: 100px;
								border-radius: 8px;
							}
						}
					}
				}
			}

			.container {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: flex-start;

				&.big {
					flex-direction: column;

					.promo {
						margin: 0 0 10px 0;
						width: 100%;
						img {
							width: 100%;
						}
					}

					.content {
						.MuiTypography-subtitle1 {
							margin-top: 4px;
						}
					}
				}

				.promo {
					margin: 0 20px 0 0;
					img {
						width: 80px;
						border-radius: 4px;
					}
				}

				.content {
					flex-grow: 1;

					.MuiTypography-subtitle1 {
						font-weight: normal;
						margin-top: 8px;
					}

					.article-badge {
						background-color: $primary-light;
						margin-bottom: 12px;
						height: 24px;

						.MuiChip-label {
							color: $primary-dark;
							font-size: $body1-size;
							line-height: $body1-line;
						}
					}
				}
			}
		}
	}
}
