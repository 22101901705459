.app-container,
.MuiDialog-root,
.MuiPopover-root {
	.MuiButton-root {
		color: $black;
		padding: 14px 21px;
		font-size: $body2-size;
		font-family: $font;
		font-weight: normal;
		line-height: $body2-line;
		border-radius: 24px;
		text-transform: uppercase;

		&.Mui-disabled {
			color: $gray-main;
		}
	}
	.MuiButton-text {
		padding: 7px 10px;
	}
	.MuiButton-textPrimary {
		color: $primary-main;
	}
	.MuiButton-textPrimary:hover {
		background-color: transparentize($primary-main, 0.96);
	}
	.MuiButton-textSecondary {
		color: $secondary-main;
	}
	.MuiButton-textSecondary:hover {
		background-color: transparentize($secondary-main, 0.96);
	}
	.MuiButton-outlined {
		padding: 13px 20px;
	}
	.MuiButton-outlined.Mui-disabled {
		border: 1px solid $gray-light;
	}
	.MuiButton-outlinedPrimary {
		color: $primary-main;
		border: 1px solid $primary-main;
	}
	.MuiButton-outlinedPrimary:hover {
		border: 1px solid $primary-main;
		background-color: transparentize($primary-main, 0.96);
	}
	.MuiButton-outlinedSecondary {
		color: $secondary-main;
		border: 1px solid $secondary-main;
	}
	.MuiButton-outlinedSecondary:hover {
		border: 1px solid $secondary-main;
		background-color: transparentize($secondary-main, 0.96);
	}
	.MuiButton-outlinedSecondary.Mui-disabled {
		border: 1px solid $gray-main;
	}
	.MuiButton-contained {
		background-color: $white;
		color: $black;

		&:hover {
			background-color: $gray-lightest;
		}
	}
	.MuiButton-contained.Mui-disabled {
		color: $gray-main;
		box-shadow: none;
		background-color: $gray-light;
	}
	.MuiButton-contained:hover.Mui-disabled {
		background-color: $gray-light;
	}
	.MuiButton-containedPrimary {
		color: $white;
		background-color: $primary-main;
	}
	.MuiButton-containedPrimary:hover {
		background-color: $primary-dark;
	}
	@media (hover: none) {
		.MuiButton-containedPrimary:hover {
			background-color: $primary-main;
		}
	}
	.MuiButton-containedSecondary {
		color: $white;
		background-color: $secondary-main;
	}
	.MuiButton-containedSecondary:hover {
		background-color: $secondary-dark;
	}
	@media (hover: none) {
		.MuiButton-containedSecondary:hover {
			background-color: $secondary-main;
		}
	}
	.MuiButton-textSizeSmall {
		padding: 4px 10px;
		font-size: 0.8125rem;
	}
	.MuiButton-textSizeLarge {
		padding: 21px;
		font-size: 0.9375rem;
	}
	.MuiButton-outlinedSizeSmall {
		padding: 3px 9px;
		font-size: 0.8125rem;
	}
	.MuiButton-outlinedSizeLarge {
		padding: 20px;
		font-size: 0.9375rem;
	}
	.MuiButton-containedSizeSmall {
		padding: 4px 10px;
		font-size: 0.8125rem;
	}
	.MuiButton-containedSizeLarge {
		padding: 21px;
		font-size: 0.9375rem;
	}
	.MuiButton-sizeLarge {
		font-size: $body2-size;
		font-weight: normal;
		line-height: $body2-line;
	}
	.MuiButton-sizeSmall {
		font-size: $subtitle-size;
		font-weight: normal;
		line-height: $subtitle-line;
	}
	.MuiButton-fullWidth {
		width: 100%;
	}
	.MuiButton-startIcon {
		display: inherit;
		margin-left: -4px;
		margin-right: 8px;
	}
	.MuiButton-startIcon.MuiButton-iconSizeSmall {
		margin-left: -2px;
	}
	.MuiButton-endIcon {
		display: inherit;
		margin-left: 8px;
		margin-right: -4px;
	}
	.MuiButton-endIcon.MuiButton-iconSizeSmall {
		margin-right: -2px;
	}
	.MuiButton-iconSizeSmall > *:first-child {
		font-size: 18px;
	}
	.MuiButton-iconSizeMedium > *:first-child {
		font-size: $body1-size;
	}
	.MuiButton-iconSizeLarge > *:first-child {
		font-size: $h4-size;
	}

	.MuiIconButton-root {
		flex: 0 0 auto;
		color: $gray-main;
		padding: 14px;
		overflow: visible;
		font-size: $body2-size;
		line-height: $body2-line;
		width: 48px;
		height: 48px;
		text-align: center;
		transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		border-radius: 50%;
	}
	.MuiIconButton-root:hover {
		background-color: transparentize($gray-main, 0.96);
	}
	.MuiIconButton-root.Mui-disabled {
		color: $gray-lightest;
		background-color: transparent;
	}
	.MuiIconButton-edgeStart {
		margin-left: -12px;
	}
	.MuiIconButton-sizeSmall.MuiIconButton-edgeStart {
		margin-left: -3px;
	}
	.MuiIconButton-edgeEnd {
		margin-right: -12px;
	}
	.MuiIconButton-sizeSmall.MuiIconButton-edgeEnd {
		margin-right: -3px;
	}
	.MuiIconButton-colorInherit {
		color: inherit;
	}
	.MuiIconButton-colorPrimary {
		color: $primary-main;
	}
	.MuiIconButton-colorPrimary:hover {
		background-color: transparentize($primary-main, 0.96);
	}
	.MuiIconButton-colorSecondary {
		color: $secondary-main;
	}
	.MuiIconButton-colorSecondary:hover {
		background-color: transparentize($secondary-main, 0.96);
	}
	.MuiIconButton-sizeSmall {
		padding: 3px;
		font-size: 1.125rem;
	}

	.MuiTypography-body2 {
		color: $gray-darkest;
		font-size: $body2-size;
		font-family: $font;
		font-weight: normal;
		line-height: $body2-line;
	}
	.MuiTypography-body1 {
		font-size: $body1-size;
		font-family: $font;
		font-weight: normal;
		line-height: $body1-line;
	}
	.MuiTypography-caption {
		color: $gray-main;
		font-size: $caption-size;
		font-family: $font;
		font-weight: normal;
		line-height: $caption-line;
	}
	.MuiTypography-button {
		font-size: $body2-size;
		font-family: $font;
		font-weight: normal;
		line-height: $body2-line;
		text-transform: none;
	}
	.MuiTypography-h1 {
		font-size: $h1-size;
		font-family: $font;
		font-weight: bold;
		line-height: $h1-line;
	}
	.MuiTypography-h2 {
		font-size: $h2-size;
		font-family: $font;
		font-weight: normal;
		line-height: $h2-line;
	}
	.MuiTypography-h3 {
		font-size: $h3-size;
		font-family: $font;
		font-weight: normal;
		line-height: $h3-line;
	}
	.MuiTypography-h4 {
		font-size: $h4-size;
		font-family: $font;
		font-weight: bold;
		line-height: $h4-line;
		margin: 4px 0 16px 0;
	}
	.MuiTypography-h5 {
		font-size: $h5-size;
		font-family: $font;
		font-weight: bold;
		line-height: $h5-line;
	}
	.MuiTypography-h6 {
		font-size: 1.25rem;
		font-family: $font;
		font-weight: 500;
		line-height: 1.6;
	}
	.MuiTypography-subtitle1 {
		color: $gray-main;
		font-size: $subtitle-size;
		font-family: $font;
		font-weight: bold;
		line-height: $subtitle-line;
	}
	.MuiTypography-subtitle2 {
		color: $gray-main;
		font-size: $subtitle-size;
		font-family: $font;
		font-weight: normal;
		line-height: $subtitle-line;
	}
	.MuiTypography-overline {
		font-size: 0.75rem;
		font-family: $font;
		font-weight: 400;
		line-height: 2.66;
		text-transform: uppercase;
	}
	.MuiTypography-gutterBottom {
		margin-bottom: $xs;
	}
	.MuiTypography-paragraph {
		margin-bottom: 16px;
	}
	.MuiTypography-colorInherit {
		color: inherit;
	}
	.MuiTypography-colorPrimary {
		color: $primary-main;
	}
	.MuiTypography-colorSecondary {
		color: $secondary-main;
	}
	.MuiTypography-colorTextPrimary {
		color: $black;
	}
	.MuiTypography-colorTextSecondary {
		color: $gray-darkest;
	}
	.MuiTypography-colorError {
		color: $error-main;
	}

	.MuiCircularProgress-colorPrimary {
		color: $primary-main;
	}
	.MuiCircularProgress-colorSecondary {
		color: $secondary-main;
	}

	.MuiIcon-colorPrimary {
		color: $primary-main;
	}
	.MuiIcon-colorSecondary {
		color: $secondary-main;
	}
	.MuiIcon-colorAction {
		color: $black;
	}
	.MuiIcon-colorError {
		color: $error-main;
	}

	.MuiFormLabel-root {
		color: $gray-darkest;
		font-size: $subtitle-size;
		font-family: $font;
		line-height: $subtitle-line;
	}
	.MuiFormLabel-root.Mui-focused {
		color: $primary-main;
	}
	.MuiFormLabel-root.Mui-disabled {
		color: $gray-darkest;
	}
	.MuiFormLabel-root.Mui-error {
		color: $error-main;
	}
	.MuiFormLabel-colorSecondary.Mui-focused {
		color: $secondary-main;
	}
	.MuiFormLabel-asterisk.Mui-error {
		color: $error-main;
	}

	.MuiInput-colorSecondary.MuiInput-underline:after {
		border-bottom-color: $secondary-light;
	}
	.MuiInput-underline:after {
		border-bottom: 2px solid $primary-light;
	}
	.MuiInput-underline.Mui-error:after {
		border-bottom-color: $error-main;
	}
	.MuiInput-underline:before {
		border-bottom: 1px solid $gray-light;
	}
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 2px solid $gray-light;
	}
	.MuiInput-underline.Mui-disabled:before {
		border-bottom-style: dotted;
	}
	@media (hover: none) {
		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom: 1px solid $gray-light;
		}
	}

	.MuiFilledInput-root {
		position: relative;
		transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		background-color: $white;
		border: 1px solid $white;
		border-radius: 8px;

		&:hover {
			background-color: $gray-lightest;
		}
		&.Mui-focused {
			background-color: $white;
			border-color: $gray-main;
		}
		&.Mui-disabled {
			background-color: $gray-light;
		}
		&.Mui-error {
			border-color: $error-main;
		}
	}
	@media (hover: none) {
		.MuiFilledInput-root:hover {
			background-color: $gray-lightest;
		}
	}
	.MuiFilledInput-colorSecondary.MuiFilledInput-underline:after {
		border-bottom-color: $secondary-main;
	}
	.MuiFilledInput-underline:after {
		border-bottom: 2px solid $primary-main;
	}
	.MuiFilledInput-underline.Mui-error:after {
		border-bottom-color: $error-main;
	}

	.MuiInputLabel-shrink {
		transform: translate(0, 1.5px) scale(1);
		transform-origin: top left;
	}
	.MuiInputLabel-filled.MuiInputLabel-shrink {
		transform: translate(12px, 10px) scale(1);
	}
	.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
		transform: translate(12px, 7px) scale(1);
	}
	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		transform: translate(14px, -6px) scale(1);
	}

	.MuiPaper-root {
		color: $black;
		background-color: $white;
	}

	.MuiButtonGroup-root {
		border-radius: 500px;
	}
	.MuiButtonGroup-grouped {
		border-radius: 500px;
		min-width: 64px;
	}
	.MuiButtonGroup-groupedTextPrimary:not(:last-child) {
		border-color: $primary-main;
	}
	.MuiButtonGroup-groupedTextSecondary:not(:last-child) {
		border-color: $secondary-main;
	}
	.MuiButtonGroup-groupedOutlinedPrimary:hover {
		border-color: $primary-main;
	}
	.MuiButtonGroup-groupedOutlinedSecondary:hover {
		border-color: $secondary-main;
	}
	.MuiButtonGroup-groupedContainedHorizontal:not(:last-child).Mui-disabled {
		border-right: 1px solid $gray-light;
	}
	.MuiButtonGroup-groupedContainedVertical:not(:last-child).Mui-disabled {
		border-bottom: 1px solid $gray-light;
	}
	.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
		border-color: $primary-main;
	}
	.MuiButtonGroup-groupedContainedSecondary:not(:last-child) {
		border-color: $secondary-main;
	}

	.MuiFormControl-marginNormal {
		margin-top: 18px;
		margin-bottom: 10px;
	}

	.MuiFormControl-marginDense {
		margin-top: 8px;
		margin-bottom: 4px;
	}

	.MuiSwitchBarLabel {
		margin-bottom: 16px;
	}

	.MuiLinearProgress-root {
		height: 4px;
	}
	.MuiLinearProgress-colorPrimary {
		background-color: $primary-light;
	}
	.MuiLinearProgress-colorSecondary {
		background-color: $secondary-light;
	}

	.MuiLinearProgress-dashedColorPrimary {
		background-size: 10px 10px;
		background-image: radial-gradient($primary-light 0%, $primary-light 16%, transparent 42%);
		background-position: 0 -23px;
	}
	.MuiLinearProgress-dashedColorSecondary {
		background-size: 10px 10px;
		background-image: radial-gradient($secondary-light 0%, $secondary-light 16%, transparent 42%);
		background-position: 0 -23px;
	}
	.MuiLinearProgress-bar {
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
		position: absolute;
		transition: transform 0.2s linear;
		transform-origin: left;
	}
	.MuiLinearProgress-barColorPrimary {
		background-color: $primary-main;
	}
	.MuiLinearProgress-barColorSecondary {
		background-color: $secondary-main;
	}

	.MuiCheckbox-root {
		color: rgba($black, 0.54);
		width: unset;
		height: unset;
	}
	.MuiCheckbox-colorPrimary {
		color: $primary-main;
	}
	.MuiCheckbox-colorPrimary.Mui-checked {
		color: $primary-main;
	}
	.MuiCheckbox-colorPrimary.Mui-disabled {
		color: rgba($black, 0.26);
	}
	.MuiCheckbox-colorPrimary.Mui-checked:hover {
		background-color: rgba($primary-main, 0.04);
	}
	.MuiCheckbox-colorSecondary {
		color: $secondary-main;
	}
	.MuiCheckbox-colorSecondary.Mui-checked {
		color: $secondary-main;
	}
	.MuiCheckbox-colorSecondary.Mui-disabled {
		color: rgba($black, 0.26);
	}
	.MuiCheckbox-colorSecondary.Mui-checked:hover {
		background-color: rgba($secondary-main, 0.04);
	}

	.MuiDialog-paper {
		background-color: $gray-lightest;
	}

	.MuiSvgIcon-colorPrimary {
		color: $primary-main;
	}
	.MuiSvgIcon-colorSecondary {
		color: $secondary-main;
	}
	.MuiSvgIcon-colorAction {
		color: rgba($black, 0.54);
	}
	.MuiSvgIcon-colorError {
		color: $error-main;
	}
	.MuiSvgIcon-colorDisabled {
		color: rgba($black, 0.26);
	}

	.MuiPickersToolbar-toolbar {
		height: 100px;

		background-color: $primary-main;

		.MuiTypography-subtitle1 {
			color: $black;
		}

		.MuiTypography-h4 {
			margin: 0 8px;
		}
	}

	.MuiPickersDay-current {
		background-color: $white;
		.MuiTypography-body2 {
			color: $primary-main;
		}
	}
	.MuiPickersDay-daySelected {
		background-color: $primary-main;
		.MuiTypography-body2 {
			color: $white;
		}
	}

	.MuiPickersCalendarHeader-switchHeader {
		margin-right: 8px;
		margin-left: 8px;
	}

	.MuiPickersDay-day {
		width: 36px;
		height: 36px;
		margin: 0 2px;
		padding: 0;
		font-size: 0.75rem;
		font-weight: 500;
		color: $black;
	}

	.MuiAutocomplete-endAdornment {
		right: 0;
		top: calc(50% - 24px);
	}
	.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
		padding-top: 24px;

		.MuiAutocomplete-endAdornment {
			right: 0;
		}
	}

	.MuiChip-root {
		color: rgba(0, 0, 0, 0.87);
		font-family: $font;
		background-color: #e0e0e0;
	}

	.MuiChip-colorPrimary {
		color: $primary-dark;
		background-color: $primary-light;
	}
	.MuiChip-colorSecondary {
		color: $secondary-dark;
		background-color: $secondary-main;
	}
	.MuiChip-clickable:hover,
	.MuiChip-clickable:focus {
		background-color: rgb(206, 206, 206);
	}
	.MuiChip-clickable:active {
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	}
	.MuiChip-clickableColorPrimary:hover,
	.MuiChip-clickableColorPrimary:focus {
		background-color: rgb(90, 181, 94);
	}
	.MuiChip-clickableColorSecondary:hover,
	.MuiChip-clickableColorSecondary:focus {
		background-color: rgb(163, 56, 182);
	}
	.MuiChip-deletable:focus {
		background-color: rgb(206, 206, 206);
	}
	.MuiChip-deletableColorPrimary:focus {
		background-color: rgb(111, 191, 115);
	}
	.MuiChip-deletableColorSecondary:focus {
		background-color: rgb(175, 82, 191);
	}
	.MuiChip-outlined {
		border: 1px solid rgba(0, 0, 0, 0.23);
		background-color: transparent;
	}
	.MuiChip-clickable.MuiChip-outlined:hover,
	.MuiChip-clickable.MuiChip-outlined:focus,
	.MuiChip-deletable.MuiChip-outlined:focus {
		background-color: rgba(0, 0, 0, 0.04);
	}
	.MuiChip-outlinedPrimary {
		color: $primary-main;
		border: 1px solid $primary-main;
	}
	.MuiChip-clickable.MuiChip-outlinedPrimary:hover,
	.MuiChip-clickable.MuiChip-outlinedPrimary:focus,
	.MuiChip-deletable.MuiChip-outlinedPrimary:focus {
		background-color: rgba(76, 175, 80, 0.04);
	}
	.MuiChip-outlinedSecondary {
		color: $secondary-main;
		border: 1px solid $secondary-main;
	}
	.MuiChip-clickable.MuiChip-outlinedSecondary:hover,
	.MuiChip-clickable.MuiChip-outlinedSecondary:focus,
	.MuiChip-deletable.MuiChip-outlinedSecondary:focus {
		background-color: rgba(156, 39, 176, 0.04);
	}
	.MuiChip-icon {
		color: #616161;
		margin-left: 5px;
		margin-right: -6px;
	}
	.MuiChip-deleteIcon {
		color: rgba(0, 0, 0, 0.26);
		width: 22px;
		cursor: pointer;
		height: 22px;
		margin: 0 5px 0 -6px;
		-webkit-tap-highlight-color: transparent;
	}
	.MuiChip-deleteIcon:hover {
		color: rgba(0, 0, 0, 0.4);
	}
	.MuiChip-deleteIconColorPrimary {
		color: rgba($primary-dark, 0.7);
	}
	.MuiChip-deleteIconColorPrimary:hover,
	.MuiChip-deleteIconColorPrimary:active {
		color: $primary-dark;
	}
	.MuiChip-deleteIconColorSecondary {
		color: rgba($secondary-dark, 0.7);
	}
	.MuiChip-deleteIconColorSecondary:hover,
	.MuiChip-deleteIconColorSecondary:active {
		color: $secondary-dark;
	}
	.MuiChip-deleteIconOutlinedColorPrimary {
		color: rgba($primary-dark, 0.7);
	}
	.MuiChip-deleteIconOutlinedColorPrimary:hover,
	.MuiChip-deleteIconOutlinedColorPrimary:active {
		color: $primary-dark;
	}
	.MuiChip-deleteIconOutlinedColorSecondary {
		color: rgba($primary-dark, 0.7);
	}
	.MuiChip-deleteIconOutlinedColorSecondary:hover,
	.MuiChip-deleteIconOutlinedColorSecondary:active {
		color: $secondary-dark;
	}

	.MuiTabs-indicator {
		height: 3px;
		background-color: $primary-main;
	}
}
