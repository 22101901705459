.view-article-dialog {
	.MuiDialog-paper {
		background-color: $gray-lightest;
		border-radius: 0;
		width: 375px;
		height: 100vh;
		max-height: 100vh;
		margin: 0;
		left: 375px;
		position: absolute;

		@media (max-width: 750px) {
			left: 0;
			width: 100%;
			max-width: 100%;
		}

		.MuiDialogTitle-root {
			padding: 0;
			display: flex;

			&.title-bg {
				color: $white;
				min-height: 300px;
				background-size: cover;

				.container {
					background: linear-gradient(transparent 40%, rgba($black, 0.5) 50%, rgba($black, 0.9) 100%);

					.MuiTypography-subtitle1 {
						color: $white;
					}
					.toolbar {
						.MuiIconButton-root {
							color: $black;
							background-color: $white;

							&:hover {
								background-color: rgba($white, 0.54);
							}
						}
					}
				}
			}

			.container {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 20px;

				.toolbar {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 20px;

					.MuiIconButton-root {
						color: $gray-darkest;
						border: 1px solid $gray-main;

						&:hover {
							background-color: rgba($black, 0.14);
						}
					}

					.article-badge {
						background-color: $primary-light;
						height: 24px;

						.MuiChip-label {
							color: $primary-dark;
							font-size: $body1-size;
							line-height: $body1-line;
						}
					}
				}

				.MuiTypography-subtitle1 {
					font-weight: normal;
					padding-top: 8px;
				}
			}
		}

		.placeholder {
			span {
				border-radius: 16px;
			}
			.medium {
				width: 70%;
			}
			.small {
				width: 40%;
			}
		}
	}
}
