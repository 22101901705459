@font-face {
	font-family: "icomoon";
	src: url("fonts/icomoon.eot?1boni3");
	src: url("fonts/icomoon.eot?1boni3#iefix") format("embedded-opentype"),
		url("fonts/icomoon.ttf?1boni3") format("truetype"), url("fonts/icomoon.woff?1boni3") format("woff"),
		url("fonts/icomoon.svg?1boni3#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.map-container {
	flex-grow: 1;
	position: relative;

	.button-filter {
		position: absolute;
		top: 24px;
		right: 24px;
		width: 140px;
		text-transform: capitalize;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15), 0 2px 5px -3px rgba(0, 0, 0, 0.15);

		&.MuiIconButton-root {
			width: 48px;
			background-color: $white;
			color: $black;
		}
	}

	.button-add-geo {
		position: absolute;
		bottom: 209px;
		right: 24px;
		text-transform: capitalize;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15), 0 2px 5px -3px rgba(0, 0, 0, 0.15);
		width: 48px;
		height: 48px;
		background-color: $white;
		color: $black;

		&:hover {
			background-color: $gray-lightest;
		}

		&.Mui-disabled {
			background-color: $white;
		}
	}

	.ya-map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;

		ymaps[class$="-controls__toolbar"] {
			margin-top: $md !important;

			ymaps[class*="-controls__toolbar_right"] {
				margin-right: 170px;

				@media (max-width: 750px) {
					margin-right: 68px;
					margin-left: 78px;
				}

				ymaps[class*="-searchbox__normal-layout"] {
					width: 528px;

					@media (max-width: 750px) {
						width: 100%;
					}

					&::after {
						border-radius: 24px;
					}

					ymaps[class*="-searchbox-input"] {
						border-radius: 24px 0 0 24px;
						height: 48px;
						padding: 10px 0 10px 20px;
						z-index: 40001;

						&::before {
							font-family: "icomoon" !important;
							font-size: 16px;
							content: "\e912";
							position: absolute;
							top: 24px;
							left: 24px;
							color: $black;
						}

						input {
							border-left: 40px solid transparent;
						}
						&[class*="-_focused"] {
							box-shadow: none;
						}
					}
					ymaps[class*="-searchbox__normal-layout"]::after {
						border-radius: 24px;
					}
					ymaps[class*="-searchbox__button-cell"] {
						& > ymaps {
							border-radius: 24px;
							background-color: $white;
							height: 48px;
							width: 72px;
							padding: 10px 10px 10px 0;
						}
					}
				}

				ymaps[class*="-search__suggest"] {
					border: none;
					left: 4px;
					right: 10px;
					// top: 8px;
					border-bottom-left-radius: 24px;
					border-bottom-right-radius: 24px;
					padding-bottom: 16px;

					@media (max-width: 750px) {
						right: -60px;
					}
				}
			}
		}

		ymaps[class*="-controls__control"] {
			ymaps[class$="-zoom"] {
				width: $xl;
				padding: $xl 0;
				height: 1px !important;

				& > ymaps[class*="-float-button"] {
					padding-left: $xl;
					border-width: 0;
					height: $xl;
					border-radius: $md;

					&[class*="-zoom__plus"] {
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}

					&[class*="-zoom__minus"] {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}

					& > ymaps[class*="-float-button-icon"] {
						border-width: $sm;
					}
				}
			}

			& > ymaps > ymaps[class*="-float-button"] {
				padding-left: $xl;
				border-width: 0;
				height: $xl;
				border-radius: $md;

				& > ymaps[class*="-float-button-icon"] {
					border-width: $sm;
				}
			}
		}
	}
}
