.gallery-dialog {
	.MuiDialog-paper {
		overflow: hidden;
		max-width: 80vw;
		.object-gallery {
			background-color: #000;
			height: 80vh;
			width: 80vw;

			.attachment-container {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					max-width: 80vw;
					max-height: 80vh;
					border-radius: $xs;
				}
			}

			.slick-dots {
				height: 28px;
				bottom: 0px;
				background-color: transparentize($white, 0.5);
			}

			.slick-arrow {
				z-index: 10;
				width: $lg;
				height: $lg;

				&::before {
					color: transparentize($white, 0.5);
					font-size: $lg;
				}
			}
			.slick-next {
				right: $xs;
			}

			.slick-prev {
				left: $xs;
			}
		}
	}
}
