.request-type-selector {
	.MuiListItem-button {
		border: 1px solid $gray-light;
		border-radius: $xs;
		margin-bottom: $sm;
		padding: $sm;

		&.Mui-selected,
		&:hover {
			background-color: $gray-lightest;
		}

		.MuiListItemText-primary {
			font-size: $body2-size;
			line-height: $body2-line;
			font-weight: 500;
		}

		.icon {
			width: 20px;
			height: 20px;
			border-radius: $md;
			font-size: 20px;
			line-height: 20px;
			padding: 14px;

			&.icon-garbage {
				background-color: $purple-light;
				color: $purple-main;
			}

			&.icon-container {
				background-color: $secondary-light;
				color: $secondary-main;
			}

			&.icon-home {
				background-color: $blue-light;
				color: $blue-main;
			}

			&.icon-msg {
				background-color: $error-light;
				color: $error-main;
			}
		}
	}
}
