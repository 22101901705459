.geo-object-card {
	width: 375px;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	max-height: 100vh;
	max-height: calc(var(--vh, 1vh) * 100);
	left: 375px;
	position: fixed;
	z-index: 10;
	display: flex;
	top: 0;

	@media (max-width: 750px) {
		width: 100vw;
		z-index: 12;
		left: 0;
	}

	.MuiPaper-root {
		flex-grow: 1;
		max-height: 100%;
		max-width: 100%;
		width: 100%;
		background-color: $gray-lightest;
		border-radius: 0;
		margin: 0;
		display: flex;
		flex-direction: column;

		.toolbar {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: $md;

			.MuiIconButton-root {
				color: $gray-darkest;
				border: 1px solid $gray-main;

				&:hover {
					background-color: rgba($black, 0.14);
				}
			}

			.geo-object-badge {
				padding: 0 6px;
				margin-left: $xs;

				&.primary {
					background-color: $primary-light;

					.MuiChip-label {
						color: $primary-dark;
					}
					.badge-dot {
						background-color: $primary-main;
					}
				}
				&.secondary {
					background-color: $secondary-light;

					.MuiChip-label {
						color: $primary-dark;
					}
					.badge-dot {
						background-color: $primary-main;
					}
				}
				&.blue {
					background-color: $blue-light;

					.MuiChip-label {
						color: $blue-dark;
					}
					.badge-dot {
						background-color: $blue-main;
					}
				}

				.badge-dot {
					width: $xs;
					height: $xs;
					border-radius: 50%;
				}
			}
		}

		.content {
			padding: 0 $md $md $md;
			overflow: auto;

			.MuiTypography-h4 {
				margin-top: 0;
				margin-bottom: $sm;
			}

			.favorites-button {
				margin-bottom: $md;
				font-weight: bold;
			}

			.object-gallery {
				margin-bottom: $xl;

				.slick-arrow {
					z-index: 10;
					width: $lg;
					height: $lg;

					&::before {
						color: $primary-main;
						font-size: $lg;
					}
				}
				.slick-next {
					right: -$xs;
				}

				.slick-prev {
					left: -$xs;
				}

				img {
					width: 120px;
					height: 80px;
					margin: 0 $xs;
					border-radius: $xs;
				}
			}

			.containers {
				padding-bottom: $md;
				border-bottom: 1px solid $gray-light;

				.MuiGrid-item {
					display: flex;
					flex-direction: row;
					padding: 0 4px;

					&:last-child {
						padding-right: 0;
					}
					&:first-child {
						padding-left: 0;
					}

					.MuiTypography-h2 {
						width: 40px;
						min-width: 40px;
						text-align: right;
					}
				}
			}

			.info-block {
				margin-top: $md;
				padding-bottom: $md;
				border-bottom: 1px solid $gray-light;
				white-space: pre-wrap;

				&.org {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}

				.MuiTypography-h5 {
					margin-bottom: $sm;
				}

				.MuiIconButton-root {
					background-color: $white;
					box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
					width: $sm;
					height: $sm;
					border-radius: $md;
					font-size: $sm;
					color: $black;
					text-align: center;
					padding: 16px;
				}

				.MuiButton-root {
					font-weight: bold;
				}

				&:last-child {
					border: none;
				}
			}

			.object-type-selector {
				.MuiListItem-button {
					border: 1px solid $gray-light;
					border-radius: $xs;
					margin-bottom: $sm;
					padding: $sm;

					&.Mui-selected,
					&:hover {
						background-color: $white;
					}

					.MuiListItemText-primary {
						font-size: $body2-size;
						line-height: $body2-line;
						font-weight: 500;
					}

					.MuiListItemIcon-root {
						width: 20px;
						height: 20px;
						min-width: 20px;
						border-radius: $md;
						font-size: 20px;
						line-height: 20px;
						padding: 14px;
						justify-content: center;
						align-items: center;
						margin-right: $sm;

						&.containers {
							background-color: $primary-light;
							color: $primary-main;
						}

						&.stationary {
							background-color: $blue-light;
							color: $blue-main;
						}

						&.temporary {
							background-color: $secondary-light;
							color: $secondary-main;
						}
					}
				}
			}

			.containers-count {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				.controls-container {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;

					.MuiTypography-h4 {
						margin: 10px;
						width: 30px;
						text-align: center;
					}

					.MuiIconButton-root {
						background-color: transparent;
						box-shadow: none;
						color: $black;

						&.Mui-disabled {
							color: $gray-main;
						}
					}
				}
			}

			.recycled-types-selector {
				margin-top: $md;
				padding-bottom: $md;

				.MuiTypography-h5 {
					margin-bottom: $sm;
				}

				.standart-checkbox {
					width: calc(100% + 28px);
					margin-right: -14px;
					margin-left: -14px;
				}
			}
		}
	}
}
