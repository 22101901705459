.app-container {
	display: flex;
	flex: 1;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

	.menu-button {
		position: absolute;
		top: $md;
		left: $md;
		z-index: 1;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15), 0 2px 5px -3px rgba(0, 0, 0, 0.15);
		background-color: $white;
		color: $gray-darkest;

		&.closed {
			display: none;
		}
	}

	.left-pannel {
		width: 375px;
		flex-shrink: 0;
		padding: 0;

		@media (max-width: 750px) {
			width: auto;
		}

		.left-pannel-paper {
			width: 375px;
			flex: 0;
			padding: 0;
			overflow: hidden;

			@media (max-width: 750px) {
				width: 100vw;
			}

			.pannel-content {
				height: 100vh;
				height: calc(var(--vh, 1vh) * 100);
				z-index: 11;
				background-color: $white;
				flex: 1;
				display: flex;
				flex-direction: column;

				.content-container {
					flex: 1;
					padding: 16px 24px;
					overflow: auto;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;

					.top-navigation {
						width: 100%;

						a {
							text-decoration: none;
							color: $black;
							&:visited {
								color: $black;
							}
						}

						.back-button {
							color: $gray-darkest;
							border: 1px solid $gray-main;

							&:hover {
								background-color: rgba($black, 0.14);
							}
						}

						nav {
							margin: 0;

							.MuiListItem-button {
								border-radius: $xs;
								.MuiListItemIcon-root {
									font-size: 20px;
									color: $gray-main;
									width: $md;
									min-width: $lg;
								}
								&:hover {
									.MuiListItemIcon-root {
										color: $primary-main;
									}
								}
							}
						}

						.page-header {
							padding-top: 16px;
						}

						.header {
							margin: 0 0 0 $xs;
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							.logo {
								width: 40px;
								height: 40px;
								background: url("../images/logo.png") no-repeat center;
								background-size: contain;
								margin-right: 6px;
							}
							.name {
								flex-grow: 1;
								h1 {
									color: $primary-main;
									font-size: 20px;
									height: 20px;
									line-height: 20px;
									margin: 0;
									padding-left: 10px;
								}
								.MuiButton-root {
									text-transform: none;
									font-size: 14px;

									.button-label {
										max-width: 170px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}
									.MuiButton-endIcon {
										margin-top: 2px;

										.icon {
											font-size: 10px;
											transform: rotate(-90deg);
										}
									}
								}
							}
						}
					}
				}

				.footer-container {
					flex: 0;
					height: 65px;
					display: flex;
					padding: 16px 24px;
					justify-content: space-between;
					flex-direction: row;

					a {
						height: 40px;
						img {
							height: 40px;
						}
					}
				}
			}
		}
	}
}
.placeholder-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.placeholder-content {
		margin-top: $md;
		text-align: center;
		background: url("./images/Question.png") top center no-repeat;
		background-size: 320px auto;
		padding-top: 260px;
		min-width: 320px;
		min-height: 320px;
	}

	&.favorites {
		.placeholder-content {
			background-image: url("./images/Heart.png");
		}
	}

	&.done {
		.placeholder-content {
			background-image: url("./images/Done.png");
		}
	}

	.MuiButton-root {
		font-weight: 800;
		align-self: flex-end;
		margin: $md 0;
	}
}

a {
	color: $primary-main;
	text-decoration: none;
	cursor: pointer;
}

.full-width {
	width: 100%;
}

.article-content {
	font-family: $font;

	img {
		width: 100%;
	}
	p {
		font-size: $body1-size;
		line-height: $body1-line;
		color: $black;
		margin-bottom: 20px;
	}

	a {
		color: $primary-main;
		text-decoration: none;
		cursor: pointer;

		&:hover,
		&:visited {
			color: $primary-dark;
			text-decoration: none;
		}
	}

	li {
		margin-bottom: 8px;
	}

	table {
		border-collapse: collapse;
		width: 100%;

		td,
		th {
			border: 1px solid $black;
			padding: 4px;
			color: $black;
		}

		th {
			background-color: $primary-main;
			color: $white;
		}

		tr {
			background-color: $white;
			&:nth-child(odd) {
				background-color: $primary-light;
			}
		}
	}

	h1 {
		font-size: $h1-size;
		font-family: $font;
		font-weight: bold;
		line-height: $h1-line;
	}
	h2 {
		font-size: $h2-size;
		font-family: $font;
		font-weight: normal;
		line-height: $h2-line;
	}
	h3 {
		font-size: $h3-size;
		font-family: $font;
		font-weight: normal;
		line-height: $h3-line;
	}
	h4 {
		font-size: $h4-size;
		font-family: $font;
		font-weight: bold;
		line-height: $h4-line;
		margin: 4px 0 16px 0;
	}
	h5 {
		font-size: $h5-size;
		font-family: $font;
		font-weight: bold;
		line-height: $h5-line;
	}

	figure {
		margin: 0;
	}
}
