.upsert-about-dialog {
	height: 100%;

	.ck-content {
		height: calc(90vh - 210px);
		max-height: calc(90vh - 210px);
		overflow: auto;
	}
	.right-col {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.MuiButtonBase-root {
			align-self: flex-end;
		}

		.gallery {
			overflow: auto;
			max-height: calc(90vh - 280px);
		}
	}
}
