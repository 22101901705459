.filters-dialog {
	.MuiDialog-paper {
		background-color: $white;
		border-radius: 0;
		width: 375px;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		max-height: 100vh;
		max-height: calc(var(--vh, 1vh) * 100);
		margin: 0;
		right: 0;
		position: absolute;

		@media (max-width: 750px) {
			left: 0;
			width: 100%;
			max-width: 100%;
		}

		.MuiDialogTitle-root {
			padding: 0;
			display: flex;

			.container {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 20px;

				.toolbar {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.MuiIconButton-root {
						color: $gray-darkest;
						border: 1px solid $gray-main;

						&:hover {
							background-color: rgba($black, 0.14);
						}
					}

					.title {
						display: flex;
						flex-direction: row;
						align-items: baseline;

						.MuiTypography-h4 {
							margin: 0;
							line-height: 35px;
						}

						.MuiButton-text {
							text-transform: capitalize;
							color: $gray-main;
							font-weight: 500;
							margin-left: $xs;
						}
					}
				}

				.MuiTypography-subtitle1 {
					font-weight: normal;
					padding-top: 8px;
				}
			}
		}

		.MuiDialogContent-root {
			.MuiBox-root {
				border-top: 1px solid $gray-light;
				margin-bottom: $md;

				.MuiTypography-h5 {
					margin-top: $md;
				}
				.MuiFormControlLabel-root {
					display: block;
					margin-right: -11px;
					padding-right: 11px;
				}
			}
		}

		.MuiDialogActions-root {
			margin: $xs $md $md $md;
		}
	}
}
