.app-container,
.MuiDialog-root {
	.gallery {
		.MuiListItem-root {
			border: 1px solid $primary-main;
			border-radius: 8px;
			margin-bottom: 8px;
			padding: 16px 48px 16px 16px;

			&.placeholder {
				.MuiListItemAvatar-root {
					background-color: transparent;

					span {
						width: 95px;
						height: 65px;
						display: block;
					}
				}

				.MuiTypography-subtitle1 {
					span {
						width: 50px;
					}
				}
			}

			.MuiListItemAvatar-root {
				width: 95px;
				height: 65px;
				margin-right: 16px;
				position: relative;
				background-color: $gray-light;

				.MuiButton-text {
					padding: 0;
				}

				.MuiAvatar-root {
					width: 95px;
					height: 65px;
					border-radius: 0;
				}

				.MuiIcon-root {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 45px;
					height: 45px;

					.icon {
						color: $white;
						font-size: 45px;
						line-height: 45px;
						width: 45px;
						height: 45px;
					}
				}

				.MuiBox-root {
					.MuiIconButton-root {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 40px;
						height: 40px;
						background-color: $gray-main;
						border-radius: 50%;
						opacity: 80%;

						.icon {
							color: $white;
							font-size: 40px;
							line-height: 40px;
							width: 40px;
							height: 40px;
						}
					}
				}
			}

			.MuiListItemText-root {
				.icon {
					color: $primary-main;
					font-size: 17px;
					margin-right: 8px;
				}

				.MuiTypography-subtitle1 {
					color: $black;
					font-weight: normal;
				}

				.MuiListItemText-secondary {
					.MuiBox-root {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.MuiTypography-subtitle1 {
							color: $gray-main;
						}
					}
				}
			}
		}

		.MuiBox-root {
			.MuiDropzoneArea-root {
				border: 2px dashed $primary-main;
				border-radius: 8px;
				min-height: 100px;
				height: 100px;
				display: flex;
				justify-content: flex-start;
				padding: 16px;
				outline: 0;

				&:focus {
					background-color: $primary-light;
				}

				.MuiDropzoneArea-textContainer {
					display: flex;
					flex-direction: row-reverse;
					justify-content: start;
					align-items: center;

					.MuiDropzoneArea-text {
						font-size: $subtitle-size;
						line-height: $subtitle-line;
						text-align: left;
						color: $black;
						text-transform: uppercase;
					}

					.MuiIcon-root {
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background-color: $gray-light;
						margin-right: 40px;
						padding: 10px;
						color: $white;
						font-size: 20px;
						line-height: 20px;
					}
				}
			}

			&.Mui-error {
				.MuiDropzoneArea-root {
					border-color: $error-main;
				}
			}
		}

		&.compact {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-right: -9px;

			.MuiListItemSecondaryAction-root {
				top: 20px;
				right: 12px;
				width: 32px;
				height: 32px;

				.MuiIconButton-root {
					background-color: $white;
					color: $black;
					border-radius: 50%;
					width: 32px;
					height: 32px;
					padding: 6px;
				}
			}

			.MuiListItem-root {
				border: none;
				border-radius: $xs;
				margin-bottom: $xs;
				padding: 0;
				width: auto;

				&.placeholder {
					.MuiListItemAvatar-root {
						background-color: transparent;

						span {
							width: 95px;
							height: 65px;
							border-radius: $xs;
							display: block;
						}
					}
				}

				.MuiListItemAvatar-root {
					border-radius: $xs;
					margin-right: $xs;

					.MuiAvatar-root {
						border-radius: $xs;
					}
				}
			}

			.MuiBox-root {
				margin: 0 $xs $xs 0;
				.MuiDropzoneArea-root {
					width: 95px;
					height: 65px;
					min-height: 65px;
					border: none;
					padding: 8px;

					.MuiDropzoneArea-textContainer {
						flex-direction: column-reverse;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;

						.MuiDropzoneArea-text {
							margin: 0;
						}

						.MuiIcon-root {
							border-radius: 0;
							margin: $xs;
							padding: 0;
							color: $black;
							background: transparent;
						}
					}
				}
			}

			&.Mui-error {
				.MuiDropzoneArea-root {
					border: 1px solid $error-main;
				}
			}
		}
	}
}
