.sign-up {
	background-color: $primary-main;
	color: $white;

	.MuiIconButton-root {
		border: 1px solid $white;
		color: $white;
	}

	.logo {
		margin: 48px auto;
		width: 160px;
		height: 160px;
		display: block;
	}
	.sign-up-form {
		text-align: center;

		.error-text {
			color: $error-main;
		}

		.MuiInputBase-input {
			font-weight: 500;
			font-size: $body2-size;
			line-height: $body2-line;
		}

		.confirm-code {
			.MuiInputBase-input {
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				font-weight: 500;
				letter-spacing: 16px;
			}
		}

		.MuiButton-contained {
			background-color: $black;
			color: $white;
			font-weight: 600;

			&.Mui-disabled {
				background-color: $gray-main;
				color: $gray-darkest;
			}
		}
		.MuiButton-outlined {
			border-color: $white;
			color: $white;

			&.Mui-disabled {
				border-color: $gray-dark;
				color: $gray-darkest;
			}
		}
	}
}
