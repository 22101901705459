.ckeditor-container {
	.ck {
		&.ck-heading_paragraph,
		&.ck-content p {
			font-size: $body1-size;
			line-height: $body1-line;
			color: $black;
			margin-bottom: 20px;
		}

		&.ck-heading_heading1,
		&.ck-content h1 {
			font-size: $h1-size;
			font-family: $font;
			font-weight: bold;
			line-height: $h1-line;
		}

		&.ck-heading_heading2,
		&.ck-content h2 {
			font-size: $h2-size;
			font-family: $font;
			font-weight: normal;
			line-height: $h2-line;
		}

		&.ck-heading_heading3,
		&.ck-content h3 {
			font-size: $h3-size;
			font-family: $font;
			font-weight: normal;
			line-height: $h3-line;
		}

		&.ck-heading_heading4,
		&.ck-content h4 {
			font-size: $h4-size;
			font-family: $font;
			font-weight: bold;
			line-height: $h4-line;
			margin: 4px 0 16px 0;
		}

		&.ck-heading_heading5,
		&.ck-content h5 {
			font-size: $h5-size;
			font-family: $font;
			font-weight: bold;
			line-height: $h5-line;
		}

		&.ck-content {
			img {
				width: 100%;
			}

			a {
				color: $primary-main;
				text-decoration: none;
				cursor: pointer;

				&:hover,
				&:visited {
					color: $primary-dark;
					text-decoration: none;
				}
			}

			li {
				margin-bottom: 8px;
			}

			table {
				border-collapse: collapse;
				width: 100%;

				td,
				th {
					border: 1px solid $black;
					padding: 4px;
					color: $black;
				}

				th {
					background-color: $primary-main;
					color: $white;
				}

				tr {
					background-color: $white;
					&:nth-child(odd) {
						background-color: $primary-light;
					}
				}
			}
		}
	}
}
