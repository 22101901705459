@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");
@import "./scss/variables.scss";
@import "./scss/icons.scss";

@import "./scss/mui-theme.scss";
@import "./scss/common.scss";
@import "./scss/standart-checkbox.scss";
@import "./scss/map.scss";
@import "./scss/gallery.scss";
@import "./scss/gallery-dialog.scss";
@import "./scss/view-article-dialog.scss";
@import "./scss/filters-dialog.scss";
@import "./scss/geo-object.scss";
@import "./scss/upsert-request-dialog.scss";
@import "./scss/upsert-article-dialog.scss";
@import "./scss/ck-editor.scss";
@import "./scss/admin-dialog.scss";
@import "./scss/upsert-about-dialog.scss";

.app-container {
	@import "./scss/sign-up.scss";
	@import "./scss/news.scss";
	@import "./scss/favorites.scss";
	@import "./scss/support.scss";
}
