.favorites {
	.list-container {
		max-height: 100%;
		overflow: auto;

		.favorites-item {
			width: 100%;
			margin-bottom: $sm;
			border: 1px solid $gray-light;
			border-radius: $xs;

			.container {
				padding: $sm;
				.header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					margin-bottom: $xs;
					color: $black;

					.distance {
						margin-left: $xs;
						white-space: nowrap;
					}
				}
				.MuiTypography-subtitle1 {
					font-weight: normal;
				}
			}

			a {
				text-decoration: none;
				display: block;

				&:hover {
					background-color: $gray-lightest;
				}
			}

			&.placeholder {
				.container {
					.header {
						.MuiTypography-body2 {
							width: 200px;

							span {
								height: 16px;
								margin-bottom: $xs;
								border-radius: 10px;
							}
							&:last-child {
								width: 50px;
							}
						}
					}
					.MuiTypography-subtitle1 {
						span {
							height: 12px;
							width: 150px;
							border-radius: 6px;
						}
					}
				}
			}
		}
	}
}
