.upsert-request-dialog {
	width: 375px;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	max-height: 100vh;
	max-height: calc(var(--vh, 1vh) * 100);
	position: fixed;
	z-index: 10;
	display: flex;
	top: 0;

	.MuiDialog-paper {
		background-color: $gray-lightest;
		border-radius: 0;
		width: 375px;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		max-height: 100vh;
		max-height: calc(var(--vh, 1vh) * 100);
		margin: 0;
		left: 375px;
		position: absolute;

		@media (max-width: 750px) {
			left: 0;
			width: 100%;
			max-width: 100%;
		}

		.MuiDialogTitle-root {
			padding: 0;
			display: flex;

			.container {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 20px;

				.toolbar {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: flex-start;
					margin-bottom: 20px;

					.MuiIconButton-root {
						color: $gray-darkest;
						border: 1px solid $gray-main;
						margin-right: $sm;

						&:hover {
							background-color: rgba($black, 0.14);
						}
					}

					.MuiTypography-h4 {
						margin: 0 64px 0 0;
						text-align: center;
						flex-grow: 1;
					}
				}

				.MuiTypography-subtitle1 {
					font-weight: normal;
					padding-top: 8px;
				}
			}
		}

		.article-content {
			font-family: $font;

			img {
				width: 100%;
			}
			p {
				font-size: $body1-size;
				line-height: $body1-line;
				color: $black;
				margin-bottom: 20px;
			}

			a {
				color: $primary-main;
				text-decoration: none;

				&:hover,
				&:visited {
					color: $primary-dark;
					text-decoration: none;
				}
			}

			li {
				margin-bottom: 8px;
			}

			table {
				border-collapse: collapse;
				width: 100%;

				td,
				th {
					border: 1px solid $black;
					padding: 4px;
					color: $black;
				}

				th {
					background-color: $primary-main;
					color: $white;
				}

				tr {
					background-color: $white;
					&:nth-child(odd) {
						background-color: $primary-light;
					}
				}
			}
		}

		.placeholder {
			span {
				border-radius: 16px;
			}
			.medium {
				width: 70%;
			}
			.small {
				width: 40%;
			}
		}
	}
}
