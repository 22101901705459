@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?stj0wc');
  src:  url('fonts/icomoon.eot?stj0wc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?stj0wc') format('truetype'),
    url('fonts/icomoon.woff?stj0wc') format('woff'),
    url('fonts/icomoon.svg?stj0wc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-nav:before {
  content: "\e901";
}
.icon-bell:before {
  content: "\e902";
}
.icon-burger:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-container:before {
  content: "\e905";
}
.icon-container-map:before {
  content: "\e906";
}
.icon-cross:before {
  content: "\e907";
}
.icon-elips:before {
  content: "\e908";
}
.icon-faq:before {
  content: "\e909";
}
.icon-filter:before {
  content: "\e90a";
}
.icon-garbage:before {
  content: "\e90b";
}
.icon-geo:before {
  content: "\e90c";
}
.icon-geo-circle:before {
  content: "\e90d";
}
.icon-heart:before {
  content: "\e90e";
}
.icon-heart-fill:before {
  content: "\e90f";
}
.icon-home:before {
  content: "\e910";
}
.icon-info:before {
  content: "\e911";
}
.icon-magnifier:before {
  content: "\e912";
}
.icon-map:before {
  content: "\e913";
}
.icon-map-circle:before {
  content: "\e914";
}
.icon-map-ring:before {
  content: "\e915";
}
.icon-map-star:before {
  content: "\e916";
}
.icon-map-star_stroke:before {
  content: "\e917";
}
.icon-minus:before {
  content: "\e918";
}
.icon-msg:before {
  content: "\e919";
}
.icon-out:before {
  content: "\e91a";
}
.icon-phone:before {
  content: "\e91b";
}
.icon-photo:before {
  content: "\e91c";
}
.icon-plus:before {
  content: "\e91d";
}
.icon-plus-nocircle:before {
  content: "\e91e";
}
.icon-share:before {
  content: "\e91f";
}
.icon-sing:before {
  content: "\e920";
}
.icon-star:before {
  content: "\e921";
}
.icon-tell:before {
  content: "\e922";
}
.icon-user:before {
  content: "\e923";
}
