.standart-checkbox {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: -moz-none;
	-o-user-select: none;
	user-select: none;
	border-radius: 500px;

	&.primary {
		&:hover {
			background-color: rgba($primary-main, 0.04);
		}
	}
	&.secondary {
		background-color: rgba($secondary-main, 0.04);
	}

	.MuiCheckbox-root {
		color: rgba($black, 0.54);
		width: unset;
		height: unset;
		color: $gray-light;

		.MuiIconButton-label {
			background-color: $gray-light;
			border-radius: 12px;
			padding: 5px;
			font-size: $body2-size;
			width: $body2-size;
			height: $body2-size;
			line-height: $body2-line;
			box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
		}
	}

	.MuiCheckbox-colorPrimary {
		color: $gray-light;

		&.Mui-checked {
			color: $white;

			.MuiIconButton-label {
				background-color: $primary-main;
			}
		}

		&.Mui-disabled {
			color: rgba($black, 0.26);
		}
	}

	.MuiCheckbox-colorSecondary {
		color: $gray-light;

		&.Mui-checked {
			color: $white;

			.MuiIconButton-label {
				background-color: $secondary-main;
			}
		}

		&.Mui-disabled {
			color: rgba($black, 0.26);
		}
	}
}
